import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Call of duty team deathmatch"
              description="Introducing our latest FiveM script: Call of Duty Team Deathmatch, designed for competitive multiplayer experiences in High Life Role Play. This script includes three unique modes that challenge players in different ways, allowing them to enter battle, compete in teams, and prove their skills!."
              ghLink="https://github.com/TshentroTech"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Custom High Life Roleplay Loading Screen"
              description="We’ve introduced a brand-new custom loading screen for High Life Roleplay, designed to enhance the player experience as you connect to the server. This new screen features:

Background Music: A custom soundtrack to set the mood while you wait for the server to load. You can enjoy the music as the server prepares your session.
Progress Bar: A dynamic progress bar that changes colors as it advances, giving players a clear visual indication of how far along the loading process is.
Discord Integration: A clickable Discord icon that lets players easily join our community and stay connected while they wait.
Server Branding: The server’s unique logo and branding elements are displayed prominently, reinforcing the High Life experience right from the moment players connect."
              ghLink="https://github.com/TshentroTech"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="GTA FIVEM rocket league"
              description="GTA FIVEM rocket league:

New Stadium Maps: Featuring custom-made arenas that allow for creative and challenging gameplay.
Multiple Vehicles: Choose your car and get ready to compete in this fast-paced mode.
Smooth Multiplayer Experience: Play against your friends or other players on the server.
So, gather your friends, hop into your vehicles, and enjoy the fast-paced thrill of Rocket League in High Life Roleplay!."
                   ghLink="https://github.com/TshentroTech"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Tunisian FiveM role-play server"
              description="Tunisian High-life RP is a dynamic FiveM role-play server offering immersive and competitive experiences without a whitelist. With custom scripts, exciting game modes, and an active community, players can dive into various scenarios while enjoying seamless gameplay. Our server fosters teamwork and creativity, allowing players to engage in government, gang activities, and more. Join our thriving community of over 4,000 members and experience the best role-play adventures in Tunisia!"
              ghLink="https://discord.gg/9smJDbApKe"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="The Tech-report script"
              description="The Tech-report script is a robust reporting tool that allows players and administrators to handle in-game reports efficiently. The script is compatible with both QBCore and ESX frameworks, automatically detecting and adjusting based on the server’s configuration.
              For handling reports, admins can join calls using in-game voice systems like pma-voice or saltychat, depending on the server configuration.
The script facilitates creating, joining, and leaving calls, and automatically updates mugshots during calls for better identification of players.
              "
              ghLink="https://github.com/TshentroTech"
              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Tshentro.Tech offers website"
              description="Tshentro.Tech offers professional website development services, including Frontend, Backend, and Full-Stack development. Our platform provides the latest web technologies to create modern and responsive websites. Customers can easily browse and purchase products like servers, with secure user authentication and seamless checkout. Powered by Firebase, our site ensures fast, scalable, and reliable performance for all users.."
              ghLink="https://github.com/TshentroTech"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
