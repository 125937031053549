import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Tshentro </span>
            I am <span className="purple"> Freelance FiveM Scripter & Web Developer</span>
            <br />
            <span className="purple">Skills & Expertise</span>
            <br />
            <span className="green">FiveM Development:</span>
            <br />
            <li className="about-activity">
              <ImPointRight /> Custom script creation for FiveM servers
            </li>
            <li className="about-activity">
              <ImPointRight /> Experience with Lua and handling complex QBCore and ESX systems
            </li>
            <li className="about-activity">
              <ImPointRight /> Converting and integrating scripts between QBCore and ESX frameworks
            </li>
            <li className="about-activity">
              <ImPointRight /> Server optimization, resource management, and customization
            </li>
            <br />
            <span className="green">Web Development:</span>
            <br />
            <li className="about-activity">
              <ImPointRight /> Front-end expertise: HTML, CSS, JavaScript
            </li>
            <li className="about-activity">
              <ImPointRight /> Strong proficiency in modern frameworks like React
            </li>
            <li className="about-activity">
              <ImPointRight /> Responsive web design and UI/UX optimization
            </li>
            <li className="about-activity">
              <ImPointRight /> Integration of back-end services and APIs into web apps
            </li>
            <br />
      

      
            
          </p>
          
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
